import { toast } from "react-toastify";
import { useAuth } from "../../../providers/Auth";
import api from "../../../services/api";
import { ModalPropostasNfsPendentesStyle } from "./style";
import { useEffect, useState } from "react";
import Loading from "../../Loading";
import Fechar from "../../../assets/fechar.svg";
import Aprovar from "../../../assets/ativo.svg";
import { ReactSVG } from "react-svg";

export const ModalPropostasNfsPendentes = ({ state }) => {
  const { user } = useAuth();
  const [dataProposta, setDataProposta] = useState({
    loading: false,
    data: [],
    campanhas: [],
  });

  const listarPropostasComNfsPendentes = () => {
    api
      .get(
        `/proposta-material-distribuicao/nfs-pendentes/${user.clientes}?perfil=${user.perfil}`
      )
      .then((response) => {
        const propostas = response.data.data.materiaisPropostas;
        const campanhaIdsNomesUnicos = [
          ...new Map(
            propostas.map((campanha) => [campanha.campanha_id, campanha])
          ).values(),
        ].map((campanha) => ({
          id: campanha.campanha_id,
          nome: campanha.campanha_nome,
        }));

        setDataProposta({
          loading: true,
          data: response.data.data.materiaisPropostas,
          campanhas: campanhaIdsNomesUnicos,
        });
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  };

  useEffect(() => {
    listarPropostasComNfsPendentes();
  }, []);

  return (
    <ModalPropostasNfsPendentesStyle>
      <div className="div-body">
        <span onClick={() => state(false)} className="close">
          X
        </span>
        {dataProposta.loading ? (
          dataProposta.campanhas.map((campanha) => {
            const propostasDessaCampanha = dataProposta.data.filter(
              (proposta) => proposta.campanha_id === campanha.id
            );

            return (
              <>
                <a href={`/campanha/detalhes/${campanha.id}`}>
                  {campanha.nome}:
                </a>

                <table>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Nf Fornecedor</th>
                      <th>Nf Vesta</th>
                      <th>Comprovação de Entrega</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {propostasDessaCampanha.map((proposta) => {
                        return (
                          <tr className="tr-valores">
                            <td>{proposta.nome}</td>
                            <td>
                              {proposta.nf_fornecedor ? (
                                <ReactSVG src={Aprovar} className="aprovado" />
                              ) : (
                                <ReactSVG src={Fechar} className="cancelar" />
                              )}
                            </td>
                            <td>
                              {proposta.nf_vesta ? (
                                <ReactSVG src={Aprovar} className="aprovado" />
                              ) : (
                                <ReactSVG src={Fechar} className="cancelar" />
                              )}
                            </td>
                            <td>
                              {proposta.comprovante_entrega ? (
                                <ReactSVG src={Aprovar} className="aprovado" />
                              ) : (
                                <ReactSVG src={Fechar} className="cancelar" />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  </tbody>
                </table>
              </>
            );
          })
        ) : (
          <Loading />
        )}
      </div>
    </ModalPropostasNfsPendentesStyle>
  );
};
