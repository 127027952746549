import { BoxRight, DashBoardContainer, Jobs, Main } from "./styles";
import dashboard from "../../assets/menu-dash.svg";
import { ReactSVG } from "react-svg";
import { Bar, Doughnut } from "react-chartjs-2";
import { useEffect } from "react";
import api from "../../services/api";
import { useState } from "react";
import falante from "../../assets/campanha.svg";
import relogio from "../../assets/urgent-job.svg";
import imposto from "../../assets/imposto-menu.svg";
import moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useAuth } from "../../providers/Auth";
import { toast } from "react-toastify";
import Loading from "../Loading";
import { moneyMask } from "../../utils/masks";
import { RelatorioContasPagar } from "../RelatorioContasPagar";
import { RelatorioContasReceber } from "../RelatorioContasReceber";
import { ModalPropostasNfsPendentes } from "../Modal/PropostasNfsPendentes";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const { user, token } = useAuth();
  const [campanhas, setCampanhas] = useState([]);
  const [quantidadeCampanhas, setQuantidadeCampanhas] = useState(0);
  const [valorProduzidoPorMeses, setValorProduzidoPorMeses] = useState([]);
  const [briefings, setBriefings] = useState([]);
  const [valorTotalPropostas, setValorTotalPropostas] = useState(false);
  const [tempoMedioCampanha, setTempoMedioCampanha] = useState("");
  const [briefingsAnuais, setBriefingsAnuais] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState();
  const [orcamentos, setOrcamentos] = useState([]);
  const [orcamentoAnuais, setOrcamentoAnuais] = useState([]);
  const [abrirPropostasNfsPendentes, setAbrirPropostasNfsPendentes] =
    useState(false);

  function buscarBriefingCampanha(cliente_id) {
    api
      .get("/campanha-material-briefing", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data.data.materiais;
        if (cliente_id) {
          const filtro = data.filter(
            (element) =>
              element.criador_id == cliente_id && Number(element.status) != 0
          );
          setBriefings(filtro);
        } else {
          setBriefings(data);
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  const listarPropostasAprovadas = (cliente_id) => {
    api
      .get(`/proposta-materiais/cliente/${cliente_id}`)
      .then((response) => {
        const desconstruindoArrayDeArrays =
          response.data.data.materiais.flatMap((element) => element.materiais);

        const preço = desconstruindoArrayDeArrays.reduce((element, acc) => {
          if (acc.origem === "Briefing") {
            return Number(element) + Number(acc.valor_final);
          } else {
            return Number(element) + Number(acc.valor_total);
          }
        }, 0);
        setValorTotalPropostas(preço);
      })
      .catch((error) => {
        toast.error("Erro ao buscar histórico de provas.");
      });
  };

  const buscarPrazoCampanhas = (cliente_id) => {
    api
      .get(`/historico-campanha/campanhas/finalizadas/${cliente_id}`)
      .then((response) => {
        setTempoMedioCampanha(response.data.data.prazo);
      })
      .catch((error) => {
        toast.error("Erro ao buscar histórico de provas.");
      });
  };

  const listarClientes = () => {
    api
      .get(`/clientes`)
      .then((response) => {
        setClientes(response.data.data.clientes);
      })
      .catch((error) => {
        toast.error("Erro ao buscar histórico de provas.");
      });
  };

  function buscarCliente() {
    if (user.permissoes.includes("admin") && !clienteSelecionado) {
      buscarBriefingCampanha();
      listarPropostasAprovadas();
      buscarPrazoCampanhas();
      return buscarCampanhas();
    } else if (user.permissoes.includes("admin") && clienteSelecionado) {
      buscarBriefingCampanha(clienteSelecionado);
      listarPropostasAprovadas(clienteSelecionado);
      buscarPrazoCampanhas(clienteSelecionado);
      return buscarCampanhas(clienteSelecionado);
    } else if (user.permissoes.includes("compras")) {
      buscarBriefingCampanha();
      listarPropostasAprovadas();
      buscarPrazoCampanhas();
      return buscarCampanhas();
    }

    api
      .get(`/usuario-cliente/${user.id}`)
      .then((response) => {
        buscarCampanhas(response.data.data.usuario.cliente_id);
        buscarBriefingCampanha(response.data.data.usuario.cliente_id);
        listarPropostasAprovadas(response.data.data.usuario.cliente_id);
        buscarPrazoCampanhas(response.data.data.usuario.cliente_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCampanhas(cliente_id) {
    api
      .get("/campanhas")
      .then((response) => {
        const data = response.data.data.campanhas;
        if (cliente_id) {
          const filtro = data.filter(
            (element) =>
              element.cliente_id == cliente_id && Number(element.status) != 0
          );
          setCampanhas(filtro);
        } else {
          setCampanhas(data);
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  const listarTotalCampanhaPorAno = (anoSelecionado) => {
    const months = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    campanhas.map((campanha) => {
      const tirarT = campanha.data_criacao.split(" ")[0];
      const mes = tirarT.split("/")[1];
      const ano = tirarT.split("/")[2];

      if (anoSelecionado && ano !== String(anoSelecionado)) {
        return;
      }

      months[Number(mes) - 1]++;
    });

    setQuantidadeCampanhas(months);
  };

  const buscarValoresProduzidos = (anoSelecionado, cliente) => {
    api
      .get(
        `/relatorios/media_por_mes?ano=${anoSelecionado}${
          user.permissoes.includes("admin") && !cliente
            ? ""
            : cliente
            ? "&cliente_id=" + [cliente]
            : "&cliente_id=" + user.clientes
        }`
      )
      .then((response) => {
        const months = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        response.data.data.materiais.map((campanha) => {
          const tirarT = campanha.data_criacao.split(" ")[0];
          const mes = tirarT.split("/")[1];

          months[Number(mes) - 1] += Number(campanha.valor_de_venda);
          setValorProduzidoPorMeses(months);
        });

        if (response.data.data.materiais.length === 0) {
          setValorProduzidoPorMeses([]);
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  };

  const verificarBriefingsPorAno = (anoSelecionado) => {
    const briefingsSelecionados = [];

    for (let i = 0; i < briefings.length; i++) {
      const data = moment(briefings[i].data_criacao, "DD/MM/YYYY");
      const ano = data.year();
      if (anoSelecionado && ano !== Number(anoSelecionado)) {
        continue;
      }

      briefingsSelecionados.push(briefings[i]);
    }

    setBriefingsAnuais(briefingsSelecionados);
  };

  const verificarOrcamentoPorAno = (anoSelecionado) => {
    const orcamentoSelecionados = [];

    for (let i = 0; i < orcamentos.length; i++) {
      const data = moment(orcamentos[i].data_criacao, "DD/MM/YYYY");
      const ano = data.year();
      if (anoSelecionado && ano !== Number(anoSelecionado)) {
        continue;
      }

      orcamentoSelecionados.push(orcamentos[i]);
    }

    setOrcamentoAnuais(orcamentoSelecionados);
  };

  function buscarOrcamentoBriefing(id) {
    api
      .get(`/orcamento-materiais-briefing/fornecedor/${id}`)
      .then((response) => {
        const data = response.data.data.orcamentos;
        setOrcamentos(data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarFornecedor() {
    api
      .get(`/usuario-fornecedor/${user.id}`)
      .then((response) => {
        buscarOrcamentoBriefing(response.data.data.usuario.fornecedor_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    if (user.permissoes.includes("cliente")) {
      buscarCliente();
    }

    if (
      user.permissoes.includes("fornecedor") &&
      !user.permissoes.includes("admin")
    ) {
      buscarFornecedor();
    }

    if (user.permissoes.includes("admin")) {
      listarClientes();
    }

    if (
      (user.permissoes.includes("cliente") &&
        !user.permissoes.includes("fornecedor")) ||
      user.permissoes.includes("admin")
    ) {
      buscarValoresProduzidos(new Date().getFullYear(), clienteSelecionado);
    }
  }, [clienteSelecionado]);

  useEffect(() => {
    listarTotalCampanhaPorAno(new Date().getFullYear());
  }, [campanhas]);

  useEffect(() => {
    verificarBriefingsPorAno(new Date().getFullYear());
  }, [briefings]);

  useEffect(() => {
    verificarOrcamentoPorAno(new Date().getFullYear());
  }, [orcamentos]);

  const getDataUTC = (utc) => {
    const dataAtual = new Date();
    const dataNaoFormatada = new Date(dataAtual.valueOf() - utc * 60 * 60000);

    const { dia, mes, anoCompleto, hora, minuto } = {
      dia: dataNaoFormatada.getUTCDate().toString().padStart(2, "0"),
      mes: (dataNaoFormatada.getUTCMonth() + 1).toString().padStart(2, "0"),
      anoCompleto: dataNaoFormatada.getFullYear(),
      hora: dataNaoFormatada.getUTCHours().toString().padStart(2, "0"),
      minuto: dataNaoFormatada.getUTCMinutes().toString().padStart(2, "0"),
    };

    const dataFormatada = [dia, mes, anoCompleto].join("/");
    return dataFormatada;
  };

  useEffect(() => {
    if (
      (user.permissoes.includes("cliente") &&
        !user.permissoes.includes("fornecedor")) ||
      user.permissoes.includes("admin")
    ) {
      buscarValoresProduzidos(new Date().getFullYear());
    }
  }, []);

  return (
    <DashBoardContainer>
      <BoxRight>
        <Main>
          <div className="flex">
            <ReactSVG src={dashboard} className="menu-dash" />
            <h1>Dashboard</h1>
          </div>
          {user.permissoes.includes("cliente") ? (
            campanhas &&
            campanhas &&
            briefings &&
            tempoMedioCampanha &&
            valorTotalPropostas !== false ? (
              <Jobs>
                <div className="graficos-campanha">
                  <div className="flex">
                    <h2 className="titulo-grafico">Campanha</h2>

                    {clientes.length > 0 && (
                      <select
                        onChange={(e) => setClienteSelecionado(e.target.value)}
                        className="select-clientes"
                      >
                        <option value="">Todos os clientes</option>
                        {clientes && clientes.length > 0 ? (
                          clientes.map((element) => (
                            <option value={element.id}>
                              {element.razao_social}
                            </option>
                          ))
                        ) : (
                          <></>
                        )}
                      </select>
                    )}
                  </div>

                  <div className="quantidade-campanha">
                    <div className="top-div-grafico">
                      <div className="column">
                        <h3 className="titulo-div-grafico">
                          Total de campanhas:{" "}
                        </h3>
                        <span>Mês a Mês</span>
                      </div>
                      <select
                        onChange={(e) =>
                          listarTotalCampanhaPorAno(e.target.value)
                        }
                      >
                        {Array.from(
                          { length: new Date().getFullYear() - 2024 + 1 },
                          (_, i) => 2024 + i
                        ).map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Bar
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                          },
                          title: {
                            display: false,
                          },
                        },
                        scales: {
                          x: { grid: { display: false } },
                          y: { grid: { display: false } },
                        },
                      }}
                      data={{
                        labels: [
                          "jan",
                          "fev",
                          "mar",
                          "abr",
                          "mai",
                          "jun",
                          "jul",
                          "ago",
                          "set",
                          "out",
                          "nov",
                          "dez",
                        ],
                        datasets: [
                          {
                            borderRadius: {
                              topLeft: 2,
                              topRight: 2,
                            },
                            label: "grafico",
                            data: quantidadeCampanhas && quantidadeCampanhas,
                            backgroundColor: "#FFBE00",
                          },
                        ],
                      }}
                    />
                    <div className="center">
                      <ReactSVG src={falante} className="falante" />
                      <span className="bold">
                        {quantidadeCampanhas &&
                          quantidadeCampanhas.reduce((a, b) => a + b, 0)}
                      </span>
                      <span className="span-little">
                        <span
                          style={{
                            fontWeight: 700,
                            marginRight: 2,
                            fontSize: 12,
                          }}
                        >
                          total
                        </span>
                        de campanhas feitas em 2024
                      </span>
                    </div>
                  </div>
                  <div className="status-itens">
                    <div className="top-div-grafico">
                      <div className="column">
                        <h3 className="titulo-div-grafico">Total de jobs: </h3>
                      </div>
                      <select
                        onChange={(e) =>
                          verificarBriefingsPorAno(e.target.value)
                        }
                      >
                        {Array.from(
                          { length: new Date().getFullYear() - 2024 + 1 },
                          (_, i) => 2024 + i
                        ).map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Doughnut
                      data={{
                        labels: [
                          `${
                            briefingsAnuais.filter(
                              (element) => element.status === "concluido"
                            ).length
                          } Concluídos`,
                          `${
                            briefingsAnuais &&
                            briefingsAnuais.filter(
                              (element) =>
                                element.status === "aguardando análise" ||
                                element.status === "enviado pra orçamento" ||
                                element.status ===
                                  "enviado para os fornecedores"
                            ).length
                          } aguardando retorno`,
                          `${
                            briefingsAnuais &&
                            briefingsAnuais.filter(
                              (element) => element.status === "em produção"
                            ).length
                          } Em produção`,
                          `${
                            briefingsAnuais &&
                            briefingsAnuais.filter(
                              (element) => Number(element.status) === 0
                            ).length
                          } Declinados`,
                        ],
                        datasets: [
                          {
                            label: "Briefings",
                            data: [
                              briefingsAnuais &&
                                briefingsAnuais.filter(
                                  (element) => element.status === "concluido"
                                ).length,
                              briefingsAnuais &&
                                briefingsAnuais.filter(
                                  (element) =>
                                    element.status === "aguardando análise" ||
                                    element.status ===
                                      "enviado pra orçamento" ||
                                    element.status ===
                                      "enviado para os fornecedores"
                                ).length,
                              briefingsAnuais &&
                                briefingsAnuais.filter(
                                  (element) => element.status === "em produção"
                                ).length,
                              briefingsAnuais &&
                                briefingsAnuais.filter(
                                  (element) => Number(element.status) === 0
                                ).length,
                            ],
                            backgroundColor: [
                              "#000000",
                              "#FFBE00",
                              "#609ae5",
                              "#FA5252",
                            ],
                            borderColor: [
                              "#000000",
                              "#FFBE00",
                              "#609ae5",
                              "#FA5252",
                            ],
                            borderWidth: 1,
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: true,
                            position: "bottom",
                            fullSize: true,
                            align: "start",
                          },
                        },
                      }}
                    />
                    <span className="span-center">
                      {briefingsAnuais && briefingsAnuais.length} Jobs
                    </span>
                  </div>
                  <div className="status-itens valor-campanha-grafico">
                    <div className="top-div-grafico">
                      <div className="column">
                        <h3 className="titulo-div-grafico">
                          Valor médio por Campanha:{" "}
                        </h3>
                      </div>
                    </div>
                    <ReactSVG src={imposto} />
                    <span className="valor-medio">
                      {campanhas &&
                      valorTotalPropostas !== false &&
                      valorTotalPropostas !== 0 &&
                      campanhas.length !== 0
                        ? `R$ ${(
                            valorTotalPropostas / Number(campanhas.length)
                          ).toFixed(2)}`
                        : "R$ 0,00"}
                    </span>
                  </div>
                  <div className="status-itens valor-campanha-grafico media-mes">
                    <div className="top-div-grafico">
                      <div className="column">
                        <div className="flex">
                          <div className="quantidade-campanha">
                            <div className="top-div-grafico">
                              <div className="column">
                                <h3 className="titulo-div-grafico">
                                  Valor Médio produzido no Mês:{" "}
                                </h3>
                              </div>
                              <select
                                onChange={(e) =>
                                  buscarValoresProduzidos(e.target.value)
                                }
                              >
                                {Array.from(
                                  {
                                    length: new Date().getFullYear() - 2024 + 1,
                                  },
                                  (_, i) => 2024 + i
                                ).map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <Bar
                              options={{
                                responsive: true,
                                plugins: {
                                  legend: {
                                    display: false,
                                  },
                                  title: {
                                    display: false,
                                  },
                                },
                                scales: {
                                  x: { grid: { display: false } },
                                  y: { grid: { display: false } },
                                },
                              }}
                              data={{
                                labels: [
                                  "jan",
                                  "fev",
                                  "mar",
                                  "abr",
                                  "mai",
                                  "jun",
                                  "jul",
                                  "ago",
                                  "set",
                                  "out",
                                  "nov",
                                  "dez",
                                ],
                                datasets: [
                                  {
                                    borderRadius: {
                                      topLeft: 2,
                                      topRight: 2,
                                    },
                                    label: "grafico",
                                    data:
                                      valorProduzidoPorMeses &&
                                      valorProduzidoPorMeses,
                                    backgroundColor: "#FFBE00",
                                  },
                                ],
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Jobs>
            ) : (
              <Loading />
            )
          ) : (
            <Jobs>
              <div className="graficos-campanha">
                <div className="status-itens-fornecedor">
                  <div className="top-div-grafico">
                    <div className="column">
                      <h3 className="titulo-div-grafico">Total de jobs: </h3>
                    </div>
                    <select
                      onChange={(e) => verificarOrcamentoPorAno(e.target.value)}
                    >
                      {Array.from(
                        { length: new Date().getFullYear() - 2024 + 1 },
                        (_, i) => 2024 + i
                      ).map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                  <Doughnut
                    data={{
                      labels: [
                        `${
                          orcamentoAnuais.filter(
                            (element) => element.status === "concluido"
                          ).length
                        } Concluídos`,
                        `${
                          orcamentoAnuais &&
                          orcamentoAnuais.filter(
                            (element) =>
                              element.status === "pendente" ||
                              element.status === "enviado pra orçamento" ||
                              element.status === "proposta aprovada" ||
                              element.status === "aprovado"
                          ).length
                        } aguardando retorno`,
                        `${
                          orcamentoAnuais &&
                          orcamentoAnuais.filter(
                            (element) => element.status === "em produção"
                          ).length
                        } Em produção`,
                        `${
                          orcamentoAnuais &&
                          orcamentoAnuais.filter(
                            (element) => element.status === "Não Participa"
                          ).length
                        } Declinados`,
                        `${
                          orcamentoAnuais &&
                          orcamentoAnuais.filter(
                            (element) => element.status === "recusado"
                          ).length
                        } Reprovado`,
                      ],
                      datasets: [
                        {
                          label: "Jobs",
                          data: [
                            orcamentoAnuais &&
                              orcamentoAnuais.filter(
                                (element) => element.status === "concluido"
                              ).length,
                            orcamentoAnuais &&
                              orcamentoAnuais.filter(
                                (element) =>
                                  element.status === "pendente" ||
                                  element.status === "enviado pra orçamento" ||
                                  element.status === "proposta aprovada" ||
                                  element.status === "aprovado"
                              ).length,
                            orcamentoAnuais &&
                              orcamentoAnuais.filter(
                                (element) => element.status === "em produção"
                              ).length,
                            orcamentoAnuais &&
                              orcamentoAnuais.filter(
                                (element) => element.status === "Não Participa"
                              ).length,
                            orcamentoAnuais &&
                              orcamentoAnuais.filter(
                                (element) => element.status === "recusado"
                              ).length,
                          ],
                          backgroundColor: [
                            "#000000",
                            "#FFBE00",
                            "#609ae5",
                            "#aaaaaa",
                            "#FA5252",
                          ],
                          borderColor: [
                            "#000000",
                            "#FFBE00",
                            "#609ae5",
                            "#aaaaaa",
                            "#FA5252",
                          ],
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          display: true,
                          position: "bottom",
                          fullSize: true,
                          align: "start",
                        },
                      },
                    }}
                  />
                  <span className="span-center-fornecedor">
                    {orcamentoAnuais && orcamentoAnuais.length} Jobs
                  </span>
                </div>
                <div className="status-itens-fornecedor">
                  <div className="top-div-grafico">
                    <div className="column">
                      <h3 className="titulo-div-grafico">Total de jobs: </h3>
                    </div>
                    <select
                      onChange={(e) => verificarOrcamentoPorAno(e.target.value)}
                    >
                      {Array.from(
                        { length: new Date().getFullYear() - 2024 + 1 },
                        (_, i) => 2024 + i
                      ).map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                  <Doughnut
                    data={{
                      labels: [
                        `${orcamentoAnuais
                          .filter((element) => element.status === "concluido")
                          .reduce(
                            (acc, element) => acc + Number(element.valor_total),
                            0
                          )} Concluídos`,
                        `${
                          orcamentoAnuais &&
                          orcamentoAnuais
                            .filter(
                              (element) =>
                                element.status === "pendente" ||
                                element.status === "enviado pra orçamento" ||
                                element.status === "proposta aprovada" ||
                                element.status === "aprovado"
                            )
                            .reduce(
                              (acc, element) =>
                                acc + Number(element.valor_total),
                              0
                            )
                        } aguardando retorno`,
                        `${
                          orcamentoAnuais &&
                          orcamentoAnuais
                            .filter(
                              (element) => element.status === "em produção"
                            )
                            .reduce(
                              (acc, element) =>
                                acc + Number(element.valor_total),
                              0
                            )
                        } Em produção`,
                        `${
                          orcamentoAnuais &&
                          orcamentoAnuais
                            .filter((element) => element.status === "recusado")
                            .reduce(
                              (acc, element) =>
                                acc + Number(element.valor_total),
                              0
                            )
                        } Reprovados`,
                      ],
                      datasets: [
                        {
                          label: "Jobs",
                          data: [
                            orcamentoAnuais &&
                              orcamentoAnuais
                                .filter(
                                  (element) => element.status === "concluido"
                                )
                                .reduce(
                                  (acc, element) =>
                                    acc + Number(element.valor_total),
                                  0
                                ),
                            orcamentoAnuais &&
                              orcamentoAnuais
                                .filter(
                                  (element) =>
                                    element.status === "pendente" ||
                                    element.status ===
                                      "enviado pra orçamento" ||
                                    element.status === "proposta aprovada" ||
                                    element.status === "aprovado"
                                )
                                .reduce(
                                  (acc, element) =>
                                    acc + Number(element.valor_total),
                                  0
                                ),
                            orcamentoAnuais &&
                              orcamentoAnuais
                                .filter(
                                  (element) => element.status === "em produção"
                                )
                                .reduce(
                                  (acc, element) =>
                                    acc + Number(element.valor_total),
                                  0
                                ),
                            orcamentoAnuais &&
                              orcamentoAnuais
                                .filter(
                                  (element) => element.status === "recusado"
                                )
                                .reduce(
                                  (acc, element) =>
                                    acc + Number(element.valor_total),
                                  0
                                ),
                          ],
                          backgroundColor: [
                            "#000000",
                            "#FFBE00",
                            "#609ae5",
                            "#FA5252",
                          ],
                          borderColor: [
                            "#000000",
                            "#FFBE00",
                            "#609ae5",
                            "#FA5252",
                          ],
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          display: true,
                          position: "bottom",
                          fullSize: true,
                          align: "start",
                        },
                      },
                    }}
                  />
                  <span className="span-valor-total">
                    Valor Total: <br />
                    {orcamentoAnuais &&
                      "R$" +
                        " " +
                        moneyMask(
                          String(
                            Number(
                              orcamentoAnuais.reduce(
                                (acc, element) =>
                                  acc + Number(element.valor_total),
                                0
                              )
                            ).toFixed(2)
                          )
                        )}{" "}
                  </span>
                </div>
              </div>
            </Jobs>
          )}
          {user.permissoes.includes("admin") && (
            <div className="relatorio">
              <RelatorioContasPagar />
              <RelatorioContasReceber />
            </div>
          )}
        </Main>
      </BoxRight>
      {abrirPropostasNfsPendentes && (
        <ModalPropostasNfsPendentes state={setAbrirPropostasNfsPendentes} />
      )}
    </DashBoardContainer>
  );
};

export default Dashboard;
