import { useEffect, useState } from "react";
import { ContentContainer, GerarPropostaModalContainer } from "./styles";
import Loading from "../../Loading";
import LogoVestaSvg from "../../../assets/logo-vesta.png";
import { moneyMask } from "../../../utils/masks";
import { getBase64FromUrl } from "../../../utils/files";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const GerarPropostaModal = ({ setModalOpened, campanha, materiais }) => {
  const [loading, setLoading] = useState(true);
  const [logo, setLogo] = useState("");

  useEffect(() => {
    getBase64FromUrl(LogoVestaSvg).then((response) => {
      setLogo(response);
    });
  }, []);

  useEffect(() => {
    if (logo) {
      generatePDF();
    }
  }, [logo]);

  const generatePDF = async () => {
    const borders = [false, false, false, true];
    let subtotal = 0;
    let itemIndex = 0;
    const tableBody = materiais.map((material) => {
      const porcentagemIcms =
        (Number(material.icms) * Number(material.valor_total)) / 100;

      subtotal +=
        material.origem === "Briefing"
          ? Number(material.valor_de_venda)
          : Number(material.valor_total);
      itemIndex++;

      return [
        {
          text: itemIndex,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: [
            {
              text: material.subnome
                ? material.nome_material + " " + material.subnome + "\n"
                : material.nome_material + "\n",
            },
            {
              text: material.descricao_material,
              fontSize: 8,
              margin: [0, 0, 0, 10],
            },
          ],
          border: borders,
          style: "materialTexto",
        },
        {
          text: `${material.quantidade} un`,
          border: borders,
          style: "materialTexto",
        },
        // {
        //   text: `${
        //     material.icms
        //       ? `R$${moneyMask(String(Number(porcentagemIcms).toFixed(2)))}`
        //       : "---"
        //   }`,
        //   border: borders,
        //   style: "materialTexto",
        //   alignment: "center",
        // },
        // {
        //   text: `${material.iss ? `ISS` : "---"}`,
        //   border: borders,
        //   style: "materialTexto",
        //   alignment: "center",
        // },
        {
          text: `${
            material.iss
              ? "---"
              : material.origem === "Briefing"
              ? "R$" +
                moneyMask(
                  String(
                    Number(
                      material.valor_de_venda / material.quantidade
                    ).toFixed(2)
                  )
                )
              : moneyMask(String(Number(material.valor_unidade).toFixed(2)))
          }`,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
        {
          text: `R$${moneyMask(
            material.origem === "Briefing"
              ? String(Number(material.valor_de_venda).toFixed(2))
              : String(Number(material.valor_total).toFixed(2))
          )}`,
          border: borders,
          style: "materialTexto",
          alignment: "center",
        },
      ];
    });

    var docDefinition = {
      content: [
        {
          columns: [
            {
              image: logo,
              width: 135,
              height: 40,
            },
            {
              stack: [
                {
                  text: [
                    {
                      text: "VESTA ASSESSORIA E MARKETING LTDA\n",
                      style: "header",
                    },
                    {
                      text: "CNPJ: 46.467.854/0001-83\n",
                      style: "endereco",
                    },
                    {
                      text: "Inscrição Estadual: IE 136.113.678.116\n",
                      style: "endereco",
                    },
                    {
                      text: "Inscrição Municipal : CCM 7.322.570-3\n",
                      style: "endereco",
                    },
                    {
                      text: "R. Jandiatuba, 630 - Conj 430 Bloco B1 - Vila Andrade\n",
                      style: "endereco",
                    },
                    {
                      text: "São Paulo - SP - CEP: 05716-150\n",
                      style: "endereco",
                    },
                    {
                      text: "Telefone: (11) 2737-4989\n",
                      style: "endereco",
                    },
                  ],
                },
              ],
              width: 190,
            },
          ],
          columnGap: 200,
        },
        { text: `Orçamento de Serviço nº ${campanha.id}`, style: "subheader" },
        { text: `${campanha.nome}`, style: "subheader" },

        { text: "Informações do Cliente", style: "sectionHeader" },
        {
          text: `${campanha.cliente_nome}`,
          style: "clientName",
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            [
              {
                text: `CNPJ: ${campanha.cliente_documento}`,
                style: "dadosCliente",
              },
              {
                text: `Inscrição Estadual: ${campanha.cliente_inscricao_estadual}`,
                style: "dadosCliente",
              },
            ],
            [
              {
                text: `${campanha.cliente_logradouro}`,
                style: "dadosCliente",
              },
              {
                text: `${campanha.cliente_cidade} - ${campanha.cliente_estado}- CEP: ${campanha.cliente_codigo_postal}`,
                style: "dadosCliente",
              },
            ],
            [
              {
                text: `E-mail: ${campanha.cliente_email_principal}`,
                style: "dadosCliente",
              },
              {
                text: `Telefone: ${
                  campanha.cliente_telefone_principal
                    ? campanha.cliente_telefone_principal
                    : campanha.cliente_telefone_secundario
                    ? campanha.cliente_telefone_secundario
                    : "-"
                }`,
                style: "dadosCliente",
              },
            ],
          ],
        },

        { text: "Itens do Pedido", style: "sectionHeader" },
        {
          table: {
            widths: ["10%", "35%", "20%", "15%", "20%"],
            headerRows: 1,
            heights: function (row) {
              if (row === 0) {
                return 20;
              }
              return 40;
            },
            border: "noBorders",
            body: [
              [
                {
                  text: "Item",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                { text: "Descrição", style: "tableHeader", border: borders },
                { text: "Quant.", style: "tableHeader", border: borders },
                // {
                //   text: "Valor ICMS",
                //   style: "tableHeader",
                //   border: borders,
                //   alignment: "center",
                // },
                // {
                //   text: "Imposto",
                //   style: "tableHeader",
                //   border: borders,
                //   alignment: "center",
                // },
                {
                  text: "Valor uni.",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
                {
                  text: "Valor total",
                  style: "tableHeader",
                  border: borders,
                  alignment: "center",
                },
              ],
              ...tableBody,
            ],
          },
        },
        {
          canvas: [
            {
              type: "rect",
              x: 0,
              y: 0,
              w: 150,
              h: 40,
              r: 10,
              color: "#FFBE00",
            },
          ],
          margin: [370, 20, 0, 0],
        },
        {
          text: `SUBTOTAL: R$ ${moneyMask(
            String(
              subtotal.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            )
          )}`,
          style: "subtotal",
        },
      ],
      styles: {
        header: {
          fontSize: 12,
          bold: true,
          margin: [200, 0, 0, 0],
          alignment: "right",
        },
        endereco: {
          fontSize: 8,
          margin: [200, 0, 0, 0],
          alignment: "right",
          lineHeight: 1.1,
        },
        dadosCliente: {
          fontSize: 10,
        },
        subheader: {
          fontSize: 10,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        sectionHeader: {
          fontSize: 10,
          bold: true,
          margin: [0, 20, 0, 10],
        },
        clientName: {
          fontSize: 10,
          bold: true,
        },
        materialTexto: {
          fontSize: 10,
          height: 50,
          bold: false,
          margin: [0, 10, 0, 0],
        },
        subtotal: {
          fontSize: 10,
          bold: true,
          color: "#000",
          margin: [390, -25, 0, 0],
        },
        tableHeader: {
          fontSize: 10,
          bold: true,
        },
      },
    };

    pdfMake.createPdf(docDefinition).getBlob((blob) => {
      const fileURL = URL.createObjectURL(blob);
      const newWindow = window.open(fileURL, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
      setModalOpened(false);
    });
  };

  return (
    <GerarPropostaModalContainer>
      <ContentContainer onClick={(e) => e.stopPropagation()}>
        {loading && <Loading />}
      </ContentContainer>
    </GerarPropostaModalContainer>
  );
};

export default GerarPropostaModal;
