import { ReactSVG } from "react-svg";
import { MateriaisViaVitrineStyle } from "./style";
import { useEffect, useState } from "react";
import Lampada from "../../assets/materiais-via-vitrine.svg";
import { CardMaterialViaVitrine } from "./CardMaterialViaVitrine";
import { moneyMask } from "../../utils/masks";
import { toast } from "react-toastify";
import api from "../../services/api";

export const MateriaisViaVitrine = ({
  materiaisVitrine,
  buscarMateriaisVitrine,
}) => {
  const [verMaisMaterialVitrine, setVerMaisMaterialVitrine] = useState(false);
  const [categorias, setCategorias] = useState([]);

  const verMaisVitrine = () => {
    if (verMaisMaterialVitrine === true) {
      setVerMaisMaterialVitrine(false);
    } else if (verMaisMaterialVitrine === false) {
      setVerMaisMaterialVitrine(true);
    }
  };

  const handleEnviaProposta = async () => {
    // for (const material of materiaisVitrine) {
    //   if (!material.cep) {
    //     toast.error(
    //       `Material (${material.material_vitrine}) sem endereço de entrega.`
    //     );
    //     return;
    //   }
    // }

    try {
      for (const material of materiaisVitrine) {
        await api.patch(`/campanha-material-vitrine/${material.id}`, {
          status: "proposta",
        });
      }
      toast.success("Materiais enviados para proposta com sucesso.");
      buscarMateriaisVitrine();
    } catch (error) {
      toast.error("Erro ao enviar material para proposta.");
    }
  };

  function buscarCategoriaItem() {
    api
      .get(`/categoria-item`)
      .then((response) => {
        setCategorias(response.data.data.categorias);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarCategoriaItem();
  }, []);

  return (
    <MateriaisViaVitrineStyle>
      <div className="header">
        <ReactSVG src={Lampada} />
        <h2>Material via Vitrine</h2>
        <div>
          {verMaisMaterialVitrine === false && (
            <span onClick={() => verMaisVitrine()}>ver mais</span>
          )}
          {verMaisMaterialVitrine === true && (
            <span onClick={() => verMaisVitrine()}>ver menos</span>
          )}
        </div>
      </div>
      {/* {materiaisVitrine.length > 0 && verMaisMaterialVitrine === true && (
    
      )} */}
      {materiaisVitrine.length > 0 &&
        verMaisMaterialVitrine === true &&
        materiaisVitrine.map((material) => (
          <CardMaterialViaVitrine
            key={material.id}
            material={material}
            buscarMateriaisVitrine={buscarMateriaisVitrine}
            categorias={categorias}
          />
        ))}

      {materiaisVitrine.length > 0 && verMaisMaterialVitrine === true && (
        <div className="total-container">
          <div className="total">
            <b>Valor total</b>
            <div>
              R$
              {moneyMask(
                String(
                  materiaisVitrine
                    .reduce((acc, obj) => {
                      const valorTotal =
                        Number(obj.preco_unitario) * obj.quantidade;
                      return acc + valorTotal;
                    }, 0)
                    .toFixed(2)
                )
              )}
            </div>
          </div>
          <button
            className="enviar"
            onClick={async () => await handleEnviaProposta()}
          >
            Enviar para proposta
          </button>
        </div>
      )}
    </MateriaisViaVitrineStyle>
  );
};
